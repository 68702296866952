import React, { useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useUserContext } from "../../../contexts/UserContext";
import { FaUsers, FaRegCircle, FaRegFileExcel } from "react-icons/fa";
import { GoVerified } from "react-icons/go";
import { CiMoneyCheck1 } from "react-icons/ci";
import { PiStudent } from "react-icons/pi";
import { RxUpdate } from "react-icons/rx";
import { BsClipboard2Check, BsEnvelope } from "react-icons/bs";
import { LuSchool } from "react-icons/lu";
import { GrSystem } from "react-icons/gr";
import {
  MdOutlineDashboard,
  MdOutlineInfo,
  MdOutlineVerified,
  MdLogout,
  MdSupportAgent,
  MdHome,
  MdOutlinePendingActions,
  MdOutlineBlock,
  MdNoteAdd,
} from "react-icons/md";

const ICON_SIZE = 24;

const StyledSidebar = styled.div`
  width: ${(props) =>
    props.$expanded && !props.$isMobileView
      ? "240px"
      : props.$isMobileView
      ? "100%"
      : "80px"};
  background-color: #04294b;
  color: #fff;
  display: flex;
  flex-direction: column;
  ${(props) =>
    props.$isMobileView
      ? ""
      : `
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    transition: width 0.3s;
    border-right: 1px solid #ccc;
  `}
`;

const SidebarHeader = styled.div`
  background-color: #043c70;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0;
  margin-bottom: 10px;
  font-weight: bold;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  border-radius: 6px;
`;

const MenuList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  flex-grow: 1;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 6px;
  }
  &::-webkit-scrollbar-thumb {
    background: #345676;
    border-radius: 3px;
  }
  scrollbar-width: thin;
  scrollbar-color: #345676 #04294b;
`;

const SidebarFooter = styled.div`
  background-color: #043c70;
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  margin-top: 10px;
`;

const SidebarItemContainer = styled.li`
  display: flex;
  align-items: center;
  padding: 10px;
  margin: 0 10px;
  font-size: 0.95rem;
  cursor: pointer;
  border-radius: 6px;
  white-space: nowrap;
  transition: background-color 0.2s;
  justify-content: ${(props) => (props.$expanded ? "flex-start" : "center")};
  background-color: ${(props) => (props.$active ? "#043c70" : "transparent")};

  &:hover {
    background-color: #0a4b8a;
  }
`;

const SidebarSubItemContainer = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  padding-left: 20px;
  display: ${(props) => (props.$expanded ? "block" : "none")};
`;

const SidebarItemIcon = styled.span`
  margin-right: ${(props) => (props.$expanded ? "10px" : "0")};
  display: flex;
  align-items: center;
`;

const SidebarItemText = styled.span`
  display: ${(props) => (props.$expanded ? "inline" : "none")};
  overflow: hidden;
  text-overflow: ellipsis;
`;

const SidebarSectionTitle = styled.li`
  padding: 10px;
  color: #adb5bd;
  text-transform: uppercase;
  margin-top: 20px;
  font-size: 0.75rem;
`;

const Spacer = styled.div`
  height: 1px;
  background-color: #ebebeb;
  margin: 20px;
`;

const SidebarItem = ({
  icon,
  title,
  texto,
  path,
  children,
  expanded,
  isMobileView,
  onCloseModal,
  currentPath,
}) => {
  const navigate = useNavigate();
  const { setUser, setToken } = useUserContext();
  const [open, setOpen] = useState(false);

  const isActive = path && currentPath === path; // verifica se esse item é a página atual

  const handleToggle = () => {
    if (children) {
      setOpen(!open);
    } else if (path) {
      if (path === "/login") {
        localStorage.clear();
        setUser(null);
        setToken(null);
      }
      navigate(path);
      if (onCloseModal) onCloseModal();
    }
  };

  return (
    <div>
      {title &&
        (expanded ? (
          <SidebarSectionTitle>{title}</SidebarSectionTitle>
        ) : (
          <Spacer />
        ))}
      <SidebarItemContainer
        onClick={handleToggle}
        $expanded={expanded}
        $active={isActive}
      >
        <SidebarItemIcon $expanded={expanded}>{icon}</SidebarItemIcon>
        <SidebarItemText $expanded={expanded}>{texto}</SidebarItemText>
      </SidebarItemContainer>
      {children && (
        <SidebarSubItemContainer $expanded={open}>
          {children.map((child, i) => (
            <SidebarSubItem
              key={i}
              {...child}
              expanded={expanded}
              onCloseModal={onCloseModal}
              currentPath={currentPath}
            />
          ))}
        </SidebarSubItemContainer>
      )}
    </div>
  );
};

const SidebarSubItem = ({ icon, texto, path, expanded, onCloseModal }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    if (path) {
      navigate(path);
      if (onCloseModal) onCloseModal(); // Fecha modal após navegação
    }
  };

  return (
    <SidebarItemContainer onClick={handleClick} $expanded={expanded}>
      <SidebarItemIcon $expanded={expanded}>{icon}</SidebarItemIcon>
      <SidebarItemText $expanded={expanded}>{texto}</SidebarItemText>
    </SidebarItemContainer>
  );
};

const Sidebar = ({ expanded, onToggle, isMobileView, onCloseModal }) => {
  const { user } = useUserContext();
  const location = useLocation();

  const regionais = {
    ARAGUAINA: "Araguaína",
    ARAGUATINS: "Araguatins",
    ARRAIAS: "Arraias",
    COLINAS: "Colinas do Tocantins",
    DIANOPOLIS: "Dianópolis",
    GUARAI: "Guaraí",
    GURUPI: "Gurupi",
    MIRACEMA: "Miracema do Tocantins",
    PALMAS: "Palmas",
    PARAISO: "Paraíso do Tocantins",
    PEDROAFONSO: "Pedro Afonso",
    PORTONACIONAL: "Porto Nacional",
    TOCANTINOPOLIS: "Tocantinópolis",
  };

  const nomeRegional = regionais[user.regional] || "Regional não encontrada";
  let escolaNome = "";
  let sidebarItens = [];

  const commonItemsByProfile = {
    ADMIN: [
      {
        icon: <MdOutlineDashboard size={ICON_SIZE} />,
        texto: "Dashboard",
        path: "/dashboard",
      },
      {
        icon: <LuSchool size={ICON_SIZE} />,
        texto: "Escolas",
        path: "/escolas",
      },
      {
        icon: <PiStudent size={ICON_SIZE} />,
        texto: "Estudantes",
        path: "/estudantes",
      },
      {
        icon: <CiMoneyCheck1 size={ICON_SIZE} />,
        texto: "Pagamento",
        path: "/pagamento",
      },
      {
        icon: <BsEnvelope size={ICON_SIZE} />,
        texto: "Notificações",
        path: "/notificacao",
      },
      {
        title: "Sistema",
        icon: <FaUsers size={ICON_SIZE} />,
        texto: "Usuários",
        path: "/usuarios",
      },
      {
        icon: <RxUpdate size={ICON_SIZE} />,
        texto: "Atualizações",
        path: "/atualizacoes",
      },
      {
        icon: <GrSystem size={ICON_SIZE} />,
        texto: "Cadastro",
        children: [
          {
            icon: <FaRegCircle size={8} />,
            texto: "Administrador",
            path: "cadastro/administrador",
          },
          {
            icon: <FaRegCircle size={8} />,
            texto: "Avaliador (SRE)",
            path: "cadastro/avaliador-sre",
          },
          {
            icon: <FaRegCircle size={8} />,
            texto: "Escola",
            path: "cadastro/escola",
          },
          {
            icon: <FaRegCircle size={8} />,
            texto: "Multiprofissional",
            path: "cadastro/multiprofissional",
          },
          {
            icon: <FaRegCircle size={8} />,
            texto: "Secretário",
            path: "cadastro/secretario",
          },
        ],
      },
      {
        title: "Download",
        icon: <FaRegFileExcel size={ICON_SIZE} />,
        texto: "Planilhas",
        path: "/planilhas",
      },
    ],
    SECRETARIO: [
      {
        title: "Estudantes",
        icon: <PiStudent size={ICON_SIZE} />,
        texto: "Estudantes Geral",
        path: "/estudantes",
      },
      {
        icon: <GoVerified size={ICON_SIZE} />,
        texto: "Ativos",
        path: "/ativos",
      },
      {
        icon: <BsClipboard2Check size={ICON_SIZE} />,
        texto: "Novos Homologados",
        path: "/novos-homologados",
      },
      {
        icon: <MdOutlinePendingActions size={ICON_SIZE} />,
        texto: "Cadastrados",
        path: "/cadastrados",
      },
      {
        icon: <MdOutlineBlock size={ICON_SIZE} />,
        texto: "Indeferidos",
        path: "/indeferidos",
      },
      {
        icon: <MdNoteAdd size={ICON_SIZE} />,
        texto: "Cadastro",
        path: "/cadastro/estudante",
      },
    ],
    MULTIPROFISSIONAL: [
      {
        icon: <MdOutlineDashboard size={ICON_SIZE} />,
        texto: "Dashboard",
        path: "/dashboard",
      },
      {
        title: "Download",
        icon: <FaRegFileExcel size={ICON_SIZE} />,
        texto: "Planilhas",
        path: "/planilhas",
      },
      {
        title: "Estudantes",
        icon: <PiStudent size={ICON_SIZE} />,
        texto: "Estudantes Geral",
        path: "/estudantes",
      },
      {
        icon: <GoVerified size={ICON_SIZE} />,
        texto: "Ativos",
        path: "/ativos",
      },
      {
        icon: <BsClipboard2Check size={ICON_SIZE} />,
        texto: "Novos Homologados",
        path: "/novos-homologados",
      },
      {
        icon: <MdOutlinePendingActions size={ICON_SIZE} />,
        texto: "Cadastrados",
        path: "/cadastrados",
      },
      {
        icon: <MdOutlineBlock size={ICON_SIZE} />,
        texto: "Indeferidos",
        path: "/indeferidos",
      },
    ],
    AVALIADOR: [
      {
        icon: <MdOutlineDashboard size={ICON_SIZE} />,
        texto: "Dashboard",
        path: "/dashboard",
      },
      {
        icon: <LuSchool size={ICON_SIZE} />,
        texto: "Escolas",
        path: "/escolas",
      },
      {
        title: "Download",
        icon: <FaRegFileExcel size={ICON_SIZE} />,
        texto: "Planilhas",
        path: "/planilhas",
      },
      {
        title: "Estudantes",
        icon: <PiStudent size={ICON_SIZE} />,
        texto: "Estudantes Geral",
        path: "/estudantes",
      },
      {
        icon: <GoVerified size={ICON_SIZE} />,
        texto: "Ativos",
        path: "/ativos",
      },
      {
        icon: <BsClipboard2Check size={ICON_SIZE} />,
        texto: "Novos Homologados",
        path: "/novos-homologados",
      },
      {
        icon: <MdOutlinePendingActions size={ICON_SIZE} />,
        texto: "Cadastrados",
        path: "/cadastrados",
      },
      {
        icon: <MdOutlineBlock size={ICON_SIZE} />,
        texto: "Indeferidos",
        path: "/indeferidos",
      },
    ],
    default: [
      {
        icon: <MdOutlineVerified size={ICON_SIZE} />,
        texto: "Certificados",
        path: "/certificados",
      },
    ],
  };

  switch (user.profile) {
    case "ADMIN":
      escolaNome = user.setor;
      sidebarItens = commonItemsByProfile.ADMIN;
      break;
    case "SECRETARIO":
      escolaNome = user.escola.name;
      sidebarItens = commonItemsByProfile.SECRETARIO;
      break;
    case "MULTIPROFISSIONAL":
      escolaNome = user.escola.name;
      sidebarItens = commonItemsByProfile.MULTIPROFISSIONAL;
      break;
    case "AVALIADOR":
      escolaNome = nomeRegional;
      sidebarItens = commonItemsByProfile.AVALIADOR;
      break;
    default:
      escolaNome = "Regional não encontrada";
      sidebarItens = commonItemsByProfile.default;
  }

  return (
    <StyledSidebar $expanded={expanded} $isMobileView={isMobileView}>
      <SidebarHeader onClick={!isMobileView ? onToggle : undefined}>
        {escolaNome}
      </SidebarHeader>
      <MenuList>
        <SidebarItem
          icon={<MdHome size={ICON_SIZE} />}
          texto="Página Inicial"
          path="/home"
          expanded={expanded}
          onCloseModal={onCloseModal}
          currentPath={location.pathname} // passa a rota atual
        />
        {sidebarItens.map((item, index) => (
          <SidebarItem
            key={index}
            {...item}
            expanded={expanded}
            onCloseModal={onCloseModal}
            currentPath={location.pathname}
          />
        ))}
        {expanded ? (
          <SidebarSectionTitle>Extras</SidebarSectionTitle>
        ) : (
          <Spacer />
        )}
        <SidebarItem
          icon={<MdOutlineInfo size={ICON_SIZE} />}
          texto="Sobre"
          path="/sobre"
          expanded={expanded}
          onCloseModal={onCloseModal}
          currentPath={location.pathname}
        />
      </MenuList>
      <SidebarFooter>
        <SidebarItem
          icon={<MdSupportAgent size={ICON_SIZE} />}
          texto="Suporte"
          path="/suporte"
          expanded={expanded}
          onCloseModal={onCloseModal}
          currentPath={location.pathname}
        />
        <SidebarItem
          icon={<MdLogout size={ICON_SIZE} />}
          texto="Sair"
          path="/login"
          expanded={expanded}
          onCloseModal={onCloseModal}
          currentPath={location.pathname}
        />
      </SidebarFooter>
    </StyledSidebar>
  );
};

export default Sidebar;
