import React, { useEffect } from "react";
import styled from "styled-components";

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
`;

const ModalContainer = styled.div`
  background: #04294b;
  width: 80%;
  max-width: 300px;
  border-radius: 8px;
  padding: 20px;
  overflow-y: auto;
  max-height: 90vh;

  /* Caso queira ocultar scroll também no menu se não houver necessidade, basta ajustar o overflow.
     Aqui ele está como auto, então só mostrará o scroll se precisar. */
`;

const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  color: #fff;
  font-size: 1.2rem;
  cursor: pointer;
`;

const Modal = ({ isOpen, onClose, children }) => {
  useEffect(() => {
    if (isOpen) {
      // Impede a rolagem do body
      document.body.style.overflow = "hidden";
    } else {
      // Restaura a rolagem do body
      document.body.style.overflow = "";
    }

    // Limpa o efeito no desmontar ou se mudar o estado
    return () => {
      document.body.style.overflow = "";
    };
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <Overlay onClick={onClose}>
      <ModalContainer onClick={(e) => e.stopPropagation()}>
        <ModalHeader>
          <span style={{ color: "#fff", fontWeight: "bold" }}>Menu</span>
          <CloseButton onClick={onClose}>X</CloseButton>
        </ModalHeader>
        {children}
      </ModalContainer>
    </Overlay>
  );
};

export default Modal;
