import React, { useState } from "react";
import { Chart } from "react-google-charts";
import styled from "styled-components";
import { useUserContext } from "../../contexts/UserContext";
import { Tabs, Tab } from "../../components/modal/StyledModal";
import { useDataContext } from "../../contexts/DataContext";

const GraficosWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
  width: 100%;

  @media (max-width: 768px) {
    grid-template-columns: 1fr; /* No mobile, empilha tudo em uma coluna */
    gap: 20px;
  }
`;

const Grafico = styled.div`
  background-color: transparent;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  /* Remova o overflow para não forçar barra de rolagem: */
  /* overflow-x: hidden; */
  /* Se precisar garantir que não haja rolagem interna: */
  overflow: visible;
`;

const ResponsiveTabs = styled(Tabs)`
  display: flex;
  flex-wrap: wrap; /* Permite quebrar linha no mobile */
  justify-content: center;
  gap: 10px;
`;

const Dashboard = () => {
  const { user } = useUserContext();
  const { bolsistas, escolas } = useDataContext();
  const [activeTab, setActiveTab] = useState(0);

  const backgroundColor = "transparent";
  let nomeRegional;

  if (user.profile !== "MULTIPROFISSIONAL") {
    switch (user.regional) {
      case "ARAGUAINA":
        nomeRegional = "Araguaína";
        break;
      case "ARAGUATINS":
        nomeRegional = "Araguatins";
        break;
      case "ARRAIAS":
        nomeRegional = "Arraias";
        break;
      case "COLINAS":
        nomeRegional = "Colinas do Tocantins";
        break;
      case "DIANOPOLIS":
        nomeRegional = "Dianópolis";
        break;
      case "GUARAI":
        nomeRegional = "Guaraí";
        break;
      case "GURUPI":
        nomeRegional = "Gurupi";
        break;
      case "MIRACEMA":
        nomeRegional = "Miracema do Tocantins";
        break;
      case "PALMAS":
        nomeRegional = "Palmas";
        break;
      case "PARAISO":
        nomeRegional = "Paraíso do Tocantins";
        break;
      case "PEDROAFONSO":
        nomeRegional = "Pedro Afonso";
        break;
      case "PORTONACIONAL":
        nomeRegional = "Porto Nacional";
        break;
      case "TOCANTINOPOLIS":
        nomeRegional = "Tocantinópolis";
        break;
      default:
        nomeRegional = "Regional não encontrada";
    }
  }

  // Ajuste dos gráficos para 100% da largura do container
  const chartWidth = "100%";
  const chartHeight = "300px"; // altura razoável para mobile, pode ajustar

  const options = {
    title: "Estudantes por Status",
    pieHole: 0.4,
    slices: [
      { color: "green" },
      { color: "#20B2AA" },
      { color: "red" },
      { color: "orange" },
    ],
    titleTextStyle: {
      fontSize: 18,
    },
    chartArea: { width: "90%", height: "80%" },
    backgroundColor,
  };

  const options2 = {
    title: "Estudantes por Regional",
    pieHole: 0.4,
    titleTextStyle: {
      fontSize: 18,
    },
    sliceVisibilityThreshold: 0,
    legend: {
      position: "right",
      maxLines: 2,
      textStyle: { fontSize: 12 },
    },
    chartArea: { width: "90%", height: "80%" },
    backgroundColor,
  };

  const options3 = {
    title: "Escolas por Regional",
    pieHole: 0.4,
    titleTextStyle: { fontSize: 18 },
    sliceVisibilityThreshold: 0,
    legend: {
      position: "right",
      maxLines: 2,
      textStyle: { fontSize: 12 },
    },
    chartArea: { width: "90%", height: "80%" },
    backgroundColor,
  };

  const options4 = {
    title: "Estudantes cadastrados por Escola Total - Regional " + nomeRegional,
    pieHole: 0.4,
    titleTextStyle: { fontSize: 18 },
    sliceVisibilityThreshold: 0,
    legend: {
      position: "right",
      maxLines: 2,
      textStyle: { fontSize: 12 },
    },
    chartArea: { width: "90%", height: "80%" },
    backgroundColor,
  };

  const options5 = {
    title: "Estudantes por Série e Ano",
    pieHole: 0.4,
    titleTextStyle: { fontSize: 18 },
    sliceVisibilityThreshold: 0,
    legend: {
      position: "right",
      maxLines: 2,
      textStyle: { fontSize: 12 },
    },
    chartArea: { width: "90%", height: "80%" },
    backgroundColor,
  };

  const totalAtivos = bolsistas.filter(
    (bolsista) => bolsista.status === "ativo" && bolsista.ehNovoEstudante === false
  ).length;

  const totalnovosAtivos = bolsistas.filter(
    (bolsista) => bolsista.status === "ativo" && bolsista.ehNovoEstudante === true
  ).length;

  const totalIndeferido = bolsistas.filter(
    (bolsista) => bolsista.status === "indeferido"
  ).length;

  const totalPendente = bolsistas.filter(
    (bolsista) => bolsista.status === "pendente"
  ).length;

  const totalinativo = bolsistas.filter(
    (bolsista) => bolsista.status === "inativo"
  ).length;

  const totalNono = bolsistas.filter(
    (bolsista) => bolsista.serieDescricao === "9º ANO"
  ).length;

  const totalPrimo = bolsistas.filter(
    (bolsista) => bolsista.serieDescricao === "1ª SÉRIE"
  ).length;

  const totalSegun = bolsistas.filter(
    (bolsista) => bolsista.serieDescricao === "2ª SÉRIE"
  ).length;

  const totalTerce = bolsistas.filter(
    (bolsista) => bolsista.serieDescricao === "3ª SÉRIE"
  ).length;


  const dadosStatus = [
    ["Status", "Quantidade"],
    [`Homologados (${totalAtivos})`, totalAtivos],
    [`Novos Homologados  (${totalnovosAtivos})`, totalnovosAtivos],
    [`Indeferidos (${totalIndeferido})`, totalIndeferido],
    [`Cadastrados (${totalPendente})`, totalPendente],
    [`Inativos (${totalinativo})`, totalinativo],
  ];

  const dadosSerie = [
    ["Série/Ano", "Quantidade"],
    [`9° Ano - EF (${totalNono})`, totalNono],
    [`1° Série - EM (${totalPrimo})`, totalPrimo],
    [`2° Série - EM (${totalSegun})`, totalSegun],
    [`3° Série - EM (${totalTerce})`, totalTerce],
  ];


  const EstudantesPorRegional = (regional) =>
    bolsistas.filter((b) => b.escola?.regional === regional).length;

  const estudantePorRegional = [
    ["Regional", "Total De Estudante"],
    ["Araguaína", EstudantesPorRegional("ARAGUAINA") || 0],
    ["Araguatins", EstudantesPorRegional("ARAGUATINS") || 0],
    ["Arraias", EstudantesPorRegional("ARRAIAS") || 0],
    ["Colinas do Tocantins", EstudantesPorRegional("COLINAS") || 0],
    ["Dianópolis", EstudantesPorRegional("DIANOPOLIS") || 0],
    ["Guaraí", EstudantesPorRegional("GUARAI") || 0],
    ["Gurupi", EstudantesPorRegional("GURUPI") || 0],
    ["Miracena do Tocantins", EstudantesPorRegional("MIRACEMA") || 0],
    ["Palmas", EstudantesPorRegional("PALMAS") || 0],
    ["Paraíso do Tocantins", EstudantesPorRegional("PARAISO") || 0],
    ["Pedro Afonso", EstudantesPorRegional("PEDROAFONSO") || 0],
    ["Porto Nacional", EstudantesPorRegional("PORTONACIONAL") || 0],
    ["Tocantinópolis", EstudantesPorRegional("TOCANTINOPOLIS") || 0],
  ];

  let escolaPorRegional = [];
  let escolasDaRegional = [];

  if (escolas !== "UNAUTHORIZED") {
    escolaPorRegional = [
      ["Regional", "Total De Escolas"],
      ["Araguaína", escolas.filter((item) => item.regional === "ARAGUAINA").length],
      ["Araguatins", escolas.filter((item) => item.regional === "ARAGUATINS").length],
      ["Arraias", escolas.filter((item) => item.regional === "ARRAIAS").length],
      ["Colinas do Tocantins", escolas.filter((item) => item.regional === "COLINAS").length],
      ["Dianópolis", escolas.filter((item) => item.regional === "DIANOPOLIS").length],
      ["Guaraí", escolas.filter((item) => item.regional === "GUARAI").length],
      ["Gurupi", escolas.filter((item) => item.regional === "GURUPI").length],
      ["Miracena do Tocantins", escolas.filter((item) => item.regional === "MIRACEMA").length],
      ["Palmas", escolas.filter((item) => item.regional === "PALMAS").length],
      ["Paraíso do Tocantins", escolas.filter((item) => item.regional === "PARAISO").length],
      ["Pedro Afonso", escolas.filter((item) => item.regional === "PEDROAFONSO").length],
      ["Porto Nacional", escolas.filter((item) => item.regional === "PORTONACIONAL").length],
      ["Tocantinópolis", escolas.filter((item) => item.regional === "TOCANTINOPOLIS").length],
    ];

    escolasDaRegional = [
      ["Escola", "Total de Alunos"],
      ...escolas.map((escola) => [escola.name, escola.totalBolsistas]),
    ];
  }

  return (
    <>
      <ResponsiveTabs>
        <Tab $active={activeTab === 0} onClick={() => setActiveTab(0)}>
          TODOS
        </Tab>
        <Tab $active={activeTab === 1} onClick={() => setActiveTab(1)}>
          ESCOLAS
        </Tab>
        <Tab $active={activeTab === 2} onClick={() => setActiveTab(2)}>
          ESTUDANTES
        </Tab>
      </ResponsiveTabs>

      {user.profile === "ADMIN" && (
        <GraficosWrapper>
          {activeTab !== 1 && (
            <>
              <Grafico>
                <Chart
                  width={chartWidth}
                  height={chartHeight}
                  chartType="PieChart"
                  data={dadosStatus}
                  options={options}
                />
              </Grafico>
              <Grafico>
                <Chart
                  width={chartWidth}
                  height={chartHeight}
                  chartType="PieChart"
                  data={dadosSerie}
                  options={options5}
                />
              </Grafico>
              <Grafico>
                <Chart
                  width={chartWidth}
                  height={chartHeight}
                  chartType="PieChart"
                  data={estudantePorRegional}
                  options={options2}
                />
              </Grafico>
            </>
          )}
          {activeTab !== 2 && (
            <Grafico>
              <Chart
                width={chartWidth}
                height={chartHeight}
                chartType="PieChart"
                data={escolaPorRegional}
                options={options3}
              />
            </Grafico>
          )}
        </GraficosWrapper>
      )}

      {user.profile === "AVALIADOR" && (
        <GraficosWrapper>
          {activeTab !== 1 && (
            <>
              <Grafico>
                <Chart
                  width={chartWidth}
                  height={chartHeight}
                  chartType="PieChart"
                  data={dadosStatus}
                  options={options}
                />
              </Grafico>
              <Grafico>
                <Chart
                  width={chartWidth}
                  height={chartHeight}
                  chartType="PieChart"
                  data={dadosSerie}
                  options={options5}
                />
              </Grafico>
            </>
          )}
          {activeTab !== 2 && (
            <Grafico>
              <Chart
                width={chartWidth}
                height={chartHeight}
                chartType="PieChart"
                data={escolasDaRegional}
                options={options4}
              />
            </Grafico>
          )}
        </GraficosWrapper>
      )}

      {user.profile === "MULTIPROFISSIONAL" && (
        <GraficosWrapper>
          <Grafico>
            <Chart
              width={chartWidth}
              height={chartHeight}
              chartType="PieChart"
              data={dadosStatus}
              options={options}
            />
          </Grafico>
          <Grafico>
            <Chart
              width={chartWidth}
              height={chartHeight}
              chartType="PieChart"
              data={dadosSerie}
              options={options5}
            />
          </Grafico>
          <Grafico>
            <Chart
              width={chartWidth}
              height={chartHeight}
              chartType="PieChart"
              data={estudantePorRegional}
              options={options2}
            />
          </Grafico>
        </GraficosWrapper>
      )}
    </>
  );
};

export default Dashboard;
